import React, { Component } from "react"
import { Index } from "elasticlunr"
import { Link } from "gatsby"
import path from "path"

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div className="w-box">
        <div className="wrapper-box">
          <input
            type="text"
            value={this.state.query}
            onChange={this.search}
            class="search-box"
          />
          <div className="autocomplete-box">
            <ul>
              {this.state.results.slice(0, 10).map(page => {
                const filename = path.basename(page.fileAbsolutePath)
                const link = filename.replace(/\.[^/.]+$/, "")

                const title = page.title
                  ? `${page.number}# ${page.title}`
                  : page.number
                return (
                  <li key={page.id} className="result-box">
                    <Link to={"/".concat(link)}>{title}</Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {})
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}
